import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import Layout from '@/components/Layout';
import SEO from '@/components/Seo';
import { Container, PageContainer } from '../components/elements';
import { A, P, H2, H3, Hr } from './components';

const ContentContainer = styled.div`
  display: flex;
  margin: 3rem 0;
  flex-direction: row;
  justify-content: space-between;
`;
const LeftSideContainer = styled.section`
  width: 100%;
  margin-right: 30px;
`;
const RightSideContainer = styled.aside`
  display: none;
  width: 350px;
  min-height: 50vh;
  padding-left: 30px;
  margin-top: 15px;
  border-left: 1px solid ${(props) => props.theme.border.gray};
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: block;
  }
`;

function MdxLayoutNews({ pageContext }) {
  const { item, next, previous} = pageContext;
  const { lang, body, title, date } = item;

  const shortcodes = { Link, a: A, p: P, h2: H2, h3: H3, hr: Hr };

  const itemDate = new Date(date).toLocaleDateString(lang, {
    weekday: `long`,
    year: `numeric`,
    month: `short`,
    day: `numeric`,
  });

  return (
    <Layout>
      <SEO
        lang={lang}
        title={`${title} - ${itemDate} | News | MyServerJapan`}
        description={``}
      />

      <PageContainer>
        <Container>
          <ContentContainer>
            <LeftSideContainer>
              <h2>{title}</h2>
              <MDXProvider components={shortcodes}>
                <MDXRenderer>{body}</MDXRenderer>
              </MDXProvider>
            </LeftSideContainer>
            <RightSideContainer>
              <h3>RECENT NEWS</h3>
            </RightSideContainer>
          </ContentContainer>
        </Container>
      </PageContainer>
    </Layout>
  );
}

export default MdxLayoutNews;
